import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="copy-comments"
export default class extends Controller {
  static targets = ["comment"];

  copyFirstCommentToOthers() {
    const firstCommentValue = this.commentTargets[0].value;

    this.commentTargets.forEach((commentBox) => {
      if (!commentBox.value) {
        commentBox.value = firstCommentValue;
      }
    });
  }
}
