import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  calculate_total() {
    let row = event.currentTarget.closest("tr")
    let ppu = parseFloat(row.querySelector(".ppu").value) || 0
    let quantity = parseFloat(row.querySelector(".qty").value) || 0
    let uppu = parseInt(row.querySelector(".uppu").value) || 0
    let vat_multiplier = 1.2
    let tuc = (uppu * quantity) * vat_multiplier
    let tpm = (ppu * quantity)  * vat_multiplier
    row.querySelector(".tuc").value = tuc.toFixed(2)
    row.querySelector(".tpm").value = tpm.toFixed(2)
  }
}