import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "endTime", "addEndTime" ]

  connect() {
    this.hide_show_end_time()
  }

  override_changed() {
    this.hide_show_end_time()
  }

  hide_show_end_time() {
    if (this.addEndTimeTarget.checked) {
      this.endTimeTarget.style.display = "block"
    } else {
      this.endTimeTarget.style.display = "none"
    }
  }
}