import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "smsNumbers", "enableSmsNumbers" ]

  connect() {
    this.hide_show_numbers()
  }

  enable_changed() {
    this.hide_show_numbers()
  }

  hide_show_numbers() {
    if (this.enableSmsNumbersTarget.checked) {
      this.smsNumbersTarget.style.display = "block"
    } else {
      this.smsNumbersTarget.style.display = "none"
    }
  }
}