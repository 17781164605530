import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "description", "custom", "addClause", "clause", "length", "frequency", "frequencyCopy", "amount", "pricePerUnit", "costPerUnit", "frequencyValue", "frequencyCost", "totalContractValue", "totalContractCost", "endDate", "startDate" ]

  connect() {
    this.hide_show_description()
    this.hide_show_clause()
    this.updateValues()
  }

  custom_changed() {
    this.hide_show_description()
  }

  clause_changed() {
    this.hide_show_clause()
  }

  hide_show_description() {
    if (this.customTarget.checked) {
      this.descriptionTarget.style.display = "block"
    } else {
      this.descriptionTarget.style.display = "none"
    }
  }

  hide_show_clause() {
    if (this.addClauseTarget.checked) {
      this.clauseTarget.style.display = "block"
    } else {
      this.clauseTarget.style.display = "none"
    }
  }

  updateValues() {
    let contractLength = Number(this.lengthTarget.value);
    let numberOfUnits = Number(this.amountTarget.value);
    let pricePerUnit = Number(this.pricePerUnitTarget.value);
    let costPerUnit = Number(this.costPerUnitTarget.value);

    console.log({ contractLength, numberOfUnits, pricePerUnit, costPerUnit })

    if (this.startDateTarget.value != "" && contractLength != "") {
      let startDate = new Date(this.startDateTarget.value);

      let endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + (contractLength - 1));
      let day = String(endDate.getDate()).padStart(2, '0');
      let month = String(endDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      let year = endDate.getFullYear();
      this.endDateTarget.value = `${day}/${month}/${year}`;
    }

    let selectedOption = this.frequencyTarget.options[this.frequencyTarget.selectedIndex];
    let selectedName = selectedOption ? selectedOption.text : '';
    this.frequencyCopyTarget.value = selectedName;
    this.totalContractValueTarget.value = (numberOfUnits * pricePerUnit * contractLength).toFixed(2);
    this.totalContractCostTarget.value = (numberOfUnits * costPerUnit * contractLength).toFixed(2);
    this.frequencyValueTarget.value = (numberOfUnits * pricePerUnit).toFixed(2);
    this.frequencyCostTarget.value = (numberOfUnits * costPerUnit).toFixed(2);
  }
}