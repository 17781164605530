import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sim"
export default class extends Controller {
  static targets = ["sim", "phone"];

  fetchPhoneNumber(event) {
    // Check if the phone number field is empty
    if (this.phoneTarget.value === "") {
      const simNumber = this.simTarget.value;

      if (simNumber) {
        fetch(`/phone_sims/find_phone_number?sim_number=${simNumber}`)
          .then(response => response.json())
          .then(data => {
            this.phoneTarget.value = data.phone_number || "";
          });
      }
    }
  }
}
