import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="job-selection"
export default class extends Controller {
  static targets = ["checkbox", "submitButton"]
  
  connect() {
    this.toggleSubmitButton()
  }

  toggleSubmitButton() {
    const anyChecked = this.checkboxTargets.some(checkbox => checkbox.checked)
    this.submitButtonTarget.classList.toggle("d-none", !anyChecked)
  }

  toggle(event) {
    this.toggleSubmitButton()
  }
}
