import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "resolutionNotes", "addResolutionNotes" ]

  connect() {
    this.hide_show_resolution()
  }

  resolution_changed() {
    this.hide_show_resolution()
  }

  hide_show_resolution() {
    if (this.addResolutionNotesTarget.checked) {
      this.resolutionNotesTarget.style.display = "block"
    } else {
      this.resolutionNotesTarget.style.display = "none"
    }
  }
}