import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="intercept-enter"
export default class extends Controller {
  connect() {
    this.element.addEventListener("keydown", this.interceptEnter)
  }

  interceptEnter(event) {
    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  disconnect() {
    this.element.removeEventListener("keydown", this.interceptEnter)
  }
}
