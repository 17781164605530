import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["symbol", "percentage", "factor"]

    connect() {
        console.log("Hello, Stimulus!", this.element)
    }

    search() {
        console.log("Search", event.target.value)
        var url = `${location.protocol}//${location.host}/customers/${event.target.value}/symbol`
        fetch(url, {
            headers: { accept: 'application/json'}
          }).then((response) => response.json())
          .then(data => {
              console.log(data.symbol)
              this.symbolTarget.value = data.symbol
              this.percentageTarget.value = data.percentage
              this.factorTarget.value = data.factor
        })
    }
}
