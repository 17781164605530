import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "addReminder", "reminderDate" ]

  connect() {
    this.hide_show_reminder()
  }

  reminder_changed() {
    this.hide_show_reminder()
  }

  hide_show_reminder() {
    if (this.addReminderTarget.checked) {
      this.reminderDateTarget.style.display = "block"
    } else {
      this.reminderDateTarget.style.display = "none"
    }
  }
}