import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "name", "number"];

  connect() {
    $(this.selectTarget).on("change", this.updateFields.bind(this));
  }

  disconnect() {
    $(this.selectTarget).off("change", this.updateFields.bind(this));
  }

  async updateFields() {
    const engineerId = this.selectTarget.value;

    if (engineerId) {
      const response = await fetch(`/engineers/${engineerId}/data.json`);
      const data = await response.json();

      this.nameTarget.value = data.name;
      this.numberTarget.value = data.number;
    } else {
      this.nameTarget.value = "";
      this.numberTarget.value = "";
    }
  }
}