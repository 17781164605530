import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "net", "vat", "gross", "netAmount", "unitPrice", "vatAmount", "poItems", "exchangeRate", "currency", "exchangeLink" ]

  connect() {
    this.calculate_totals()
    if (this.currencyTarget.value != "GBP") {
      this.exchangeRateTarget.style.display = "block"
      this.exchangeLinkTarget.style.display = "block"
    } else {
      this.exchangeRateTarget.style.display = "none"
      this.exchangeLinkTarget.style.display = "none"
    }
    this.changeExchangeRateLink(this.currencyTarget.value)
  }

  calculate_total() {
    let row = event.currentTarget.closest("tr")
    let unit_price = parseFloat(row.querySelector(".unit_price").value) || 0
    let quantity = parseFloat(row.querySelector(".quantity").value) || 0
    let vat_amount = parseInt(row.querySelector(".vat_percentage").value) || 0
    let vat_multiplier = 1 + (vat_amount / 100)
    let net_amount = unit_price * quantity
    let gross = net_amount * vat_multiplier
    let vat = gross - net_amount
    row.querySelector(".net_amount").value = net_amount.toFixed(2)
    row.querySelector(".vat_amount").value = vat.toFixed(2)
    row.querySelector(".gross_amount").value = gross.toFixed(2)
    this.calculate_totals()
  }

  toggleExchangeRate() {
    if (event.currentTarget.value != "GBP") {
      this.exchangeRateTarget.style.display = "block"
      this.exchangeLinkTarget.style.display = "block"
    } else {
      this.exchangeRateTarget.style.display = "none"
      this.exchangeLinkTarget.style.display = "none"
    }
    this.changeExchangeRateLink(event.currentTarget.value)
  }

  changeExchangeRateLink(currency) {
    if (currency === "USD") {
      this.exchangeLinkTarget.href = "https://www.xe.com/currencyconverter/convert/?Amount=1&From=USD&To=GBP"
    } else {
      this.exchangeLinkTarget.href = "https://www.xe.com/currencyconverter/convert/?Amount=1&From=EUR&To=GBP"
    }
  }

  calculate_totals() {
    let net_total = 0;
    let vat_total = 0;

    this.netAmountTargets.forEach((netAmountInput, index) => {
        // Check if the row is marked for deletion
        let row = netAmountInput.closest("tr");
        if (row.style.display !== "none") {
            let net_amount = parseFloat(netAmountInput.value) || 0;
            let vatAmountInput = this.vatAmountTargets[index];
            let vat_amount = parseFloat(vatAmountInput.value) || 0;

            net_total += net_amount;
            vat_total += vat_amount;
        }
    });

    this.netTarget.textContent = net_total.toFixed(2);
    this.vatTarget.textContent = vat_total.toFixed(2);
    this.grossTarget.textContent = (net_total + vat_total).toFixed(2);
  }

  addItem() {
    let n = this.netAmountTargets.length
    let p = n - 1
    let clone = event.currentTarget.parentElement.parentElement.cloneNode(true)
    var inputs = clone.querySelectorAll("input")
    inputs.forEach(input => input.value = "")
    var tds = clone.querySelectorAll("td")
    tds.forEach(td => {
      if(td.children.length > 0) {
        var name = td.firstElementChild.getAttribute("name")
        var id = td.firstElementChild.getAttribute("id")

        if (name) {
          name = name.replace(p.toString(), n.toString())
          td.firstElementChild.setAttribute("name", name)  
        }
        if (id) {
          id = id.replace(p.toString(), n.toString())
          td.firstElementChild.setAttribute("id", id)
        }
      }
    })

    this.poItemsTarget.appendChild(clone);
  }

  deleteItem() {
    let row = event.currentTarget.closest("tr")
    row.querySelector("input[name*='_destroy']").value = "1"
    row.style.display = "none"
    this.calculate_totals()
  }
}
